.MuiBox-root {

    padding: 0 !important;

}
#progress-box0 {

    padding: 0 !important;

}
#progress-box {

    padding: 0 !important;
    margin-top: 15px;

}
#progress-box1 {

    padding: 0 !important;

}
#progress-box2 {

    padding: 0 !important;

}
/* .MuiLinearProgress-bar1Determinate {
    background-color: #F96B13 !important
} */