@import "../../assets/scss/material-dashboard-pro-react/colors";


.table-container table {
  border-spacing: 0;
  background-color: white;
  width: 100%;
}
.table-container tr:last-child {
    border-bottom: 0;
}


.table-container th {
  font-weight: normal;
  font-size: 14px;
  color: $criterion-grey;
  padding: 15px 15px 15px 20px;
}

.table-container td {
  margin: 0;
  padding: 15px;
  padding-left: 20px;
  border-bottom: 1px solid #c2c9d1;

  :last-child {
    border-right: 0;
  }
}


.table-checkbox {
  display: flex;
  justify-content: center;
}

.pagination {
  padding: 0.5rem 0px;
}

.show-item-dropdown {
  border-color: #FFFFFF;
    width: 107px;
    height: 24px;
    background: #FFFFFF;
    color: #0F1A2B;
    font-weight: 300;
    font-size: 14px;
    margin: 20px auto;
    opacity: 0.6;
    text-align: center;
}

.table-pagination {
  .MuiPagination-ul {
    li {
      &:first-child {
        .MuiPaginationItem-page {
          background: unset;
          svg {
            display: none;
          }
          &:after {
            content: "Previous";
            font-family: Roboto;
            font-weight: 300;
            font-size: 14px;
            color: #0F1A2B;
            margin-right: 40px;
          }
        }
      }
      &:nth-last-child(2) {
        margin-right: 10px;
      }
      &:last-child {
        .MuiPaginationItem-page {
          background: unset;
          svg {
            display: none;
          }
          &:after {
            content: "Next";
            font-family: Roboto;
            font-weight: 300;
            font-size: 14px;
            color: #0F1A2B;
          }
        }
      }
    }
  }
  .MuiPaginationItem-page {
    background: #FFFFFF;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #0F1A2B;
    border: 0;
    border-radius: 0;
    margin: 0;
    width: 24px;
    height: 24px;
    &.Mui-selected {
      background-color: #3868C2 !important;
      font-weight: 300;
      font-size: 14px;
      color: #fff !important;
    }
  }
}

.list-view {
  .search-header {
    .tablesearch-input {
      margin-top: 0;
      .MuiFilledInput-root {
        height: 48px;
        background: #ffffff;
      }
    }
  }
}


.tableView{
  .white-section{
    padding: unset;
  }
}