/*!

=========================================================
* Material Dashboard PRO React - v1.8.0 Based on Material Dashboard PRO v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/


// variables and mixins
@import "material-dashboard-pro-react/variables";
@import "material-dashboard-pro-react/mixins";
@import "material-dashboard-pro-react/shadows";

// plugin css
@import "material-dashboard-pro-react/plugins/plugin-nouislider";
@import "material-dashboard-pro-react/plugins/plugin-perfect-scrollbar";
@import "material-dashboard-pro-react/plugins/plugin-react-datetime";
@import "material-dashboard-pro-react/plugins/plugin-react-bootstrap-sweetalert";
@import "material-dashboard-pro-react/plugins/plugin-react-chartist";
@import "material-dashboard-pro-react/plugins/plugin-react-big-calendar";
@import "material-dashboard-pro-react/plugins/plugin-react-jvectormap";
@import "material-dashboard-pro-react/plugins/plugin-react-table";
@import "material-dashboard-pro-react/plugins/plugin-react-tagsinput";

// Core CSS
@import "material-dashboard-pro-react/misc";
@import "material-dashboard-pro-react/fileupload";
@import "material-dashboard-pro-react/fixed-plugin";

body {
  background-color: #f6f6f6 !important;
 
}
.makeStyles-loginTitle-48{
    margin-top: 4em ;
  }
  // .signup h4{margin-top:2em}
  .homespace{
    padding: 1em 5em 0px 5em;
}
.page-title h6{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #6F6F6F;
    padding-left: 2em;
}
.mt-3{margin-top:3em;}



.MuiList-padding li a{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 60px;
  min-height: 60px;
  padding-left: 25px;
}
.MuiList-padding li a span{
  // margin-right: 6px;
  padding-left: 4px;
  padding-right: 23px;
}

.side-menu-inner{
  
  padding-left: 0px;
  margin-top: 10px;
 }
.sidebar-logo{
  margin-top: 30px;
}


.side-menu li a img{
	    padding-right: 17px;
    padding-left: 53px;
}

.side-menu-inner{
	 background:url(../img/sub-arrow.png) no-repeat 61px -12px;
}
.side-menu-inner li a{
   font-family: Roboto;
   font-style: normal;
   font-weight: inherit;
   font-size: 15px;
   line-height: 30px !important;
   min-height: 30px !important;
   display: flex;
   align-items: center;
   text-transform: capitalize;
   color: rgba(255, 255, 255, 0.5);
   margin-top: 0px;
   padding-left: 76px  !important;
}
#logo2{
  margin-top: 20px;
}
.colapse-sidemenu{
  float:right; 
  background:#15253d;
}
.colapse-sidemenu button{
  float:right; 
}


@media screen and (max-width:767px) {
  .colapse-sidemenu{
    display: none;
  }
}

.right-button button{
  float: right;
    margin-top: 0px;
    padding-top: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px !important;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration-line: underline;
}
.mt-2{
  margin-top: 2em !important;
}
.search-container svg{
  margin-top: 15px;
  float: left;
  margin-left: 13px;
  margin-right: 15px;
}
#sandbox-mt-0{
  margin-top: 0px;
}

.Sidebar-drawerPaperMini-17 .MuiList-padding li a{
  padding-left: 21px;
}

.Sidebar-drawerPaperMini-17 .side-menu-inner {
  background: url(../img/sub-arrow.png) no-repeat 35px -11px;
}

.card-sand-left{
float: left;
}

.card-sand-right{
  float: left;
}

.float-right{
  float: right;
}
.float-left{
  float: left;
}
.float-right button{
  color: #F96B13;
  float: right;
  margin-top: 0px;
  padding-top: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  background: transparent;
  border: none;
}

.launch-demo .lock{
  color: #F96B13;
}
.lock{
  color: #F96B13 !important;
  width: 16px !important;
    height: 16px !important;
}


.side-menu-inner li a.active{
  color:#fff;
  // background: url(../img/submenu-select.png) no-repeat 42px 7px;
  
}

.reset-password .loginContainer {
  background: #fff;
}

@media screen and (max-width:1400px) {
  .makeStyles-loginContainer-3 {
    height: 106% !important;
   min-height: 106vh  !important;
    background: url("../img/background-image.png");
  }
  // .makeStyles-advertisingGridItemContainer-31 {
  //   height: 130vh  !important;
   
  // } 
  // .makeStyles-wrapper-1 {
  //   min-height: 130vh  !important;
  //   height: 130vh  !important;

  // }
  // .makeStyles-fullPage-2 {
  //   min-height: 130vh  !important; 
  //   height: 130vh  !important;

  // }

}

@media screen and (max-width:1250px) {
  .makeStyles-loginContainer-3 {
    height: 125% !important;
   min-height: 125vh  !important;

  }


}


@media screen and (max-width:1220px) {

  .makeStyles-loginContainer-3 {
    height: 160% !important;
   min-height: 160vh  !important;
   
  }

  // .makeStyles-loginContainer-3 {
  //   height: 130% !important;
  // }
  // .makeStyles-advertisingGridItemContainer-31 {
  //   height: 130vh  !important;
  // } 
  // .makeStyles-wrapper-1 {
  //   min-height: 130vh  !important;
  // }
  // .makeStyles-fullPage-2 {
  //   min-height: 130vh  !important; 
  // }

}


@media screen and (max-width:1024px) {

  .side-bottom-menu{
    position: relative !important;
  }

}

 @media (max-width: 959.95px){

  .makeStyles-loginContainer-3 {
    height: 166% !important;
   min-height: 166vh  !important;
   
  }

 }




@media screen and (max-width:767px) {
  .MuiList-padding li a{

  }
  .homespace {
    padding: 0em 0em 0px 0em;
  }
  .home-sub-title {
    padding-top: 12px;
    }

  

      
}

@media(max-height:767px)
{
  .side-bottom-menu{
    position: relative !important;
    }
    .Sidebar-drawerPaper-12 {
      overflow: hidden;
  }

 }
//  .MuiDrawer-paper{
//   overflow: hidden !important; 
// } 