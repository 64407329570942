.import-design-modal {
    .title {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      color: #000000;
      margin-top: 0;
    }
    .closebtn {
      color: #FFFFFF;
      cursor: pointer;
      position: absolute;
      font-size: 3rem;
      margin-top: -64px;
      margin-left: 75px;
      
    }
    .main-container {
      border: unset;
      .MuiGrid-item {
        padding: 0 !important;
      }
    }
  }
  
  