.design-page {
  margin-top: 40px;
  .design-card {
    background-color: white;
    width: 80%;
    margin: 0 auto;
    padding: 30px;
    .MuiGrid-root {
      // text-align: center;
      ul li {
        text-align: left;
        // padding-left: 130px;
      }
    }
  }
  .request-button,
  .request-button-disable {
    cursor: pointer;
    border: 1px solid #f96b13;
    // width: 119px;
    height: 40px;
    color: #f96b13;
    background-color: #ffffff;
  }
  .request-button-disable {
    // border: 1px solid #6b727d;
    // color: #6b727d;
    opacity: 0.4;
    cursor: not-allowed;
  }
  .align-center {
    justify-content: center;
    text-align: center;
    display: flex;
    margin-top: 70px;
  }
  .feature-box {
    height: 100px;
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-right: 15px;
    cursor: pointer;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    color: #3D3D3D;
    width: 154px;
    border: none;
    padding-top: 10px;
    height: 86px;
    background: #F4F5F7;
    border: 1px solid #E2E2E2;
    margin: 0px 20px;
    .box-label {
      font-family: Roboto;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      text-transform: capitalize;
      color: #3d3d3d;
      margin: 10px 15px 0;

      // css ellipsis
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .box-icon {
      .size35 {
        width: 35px !important;
        height: 35px;
      }
    }
    &.active {
      background: #3868c2;
      .box-label {
        color: #ffffff;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
  .new-template {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .label {
      font-family: Roboto;
      font-weight: normal;
      font-size: 16px;
      color: #000000;
    }
    .content {
      margin-top: 22px;
      display: flex;
    }
  }
  .MuiSvgIcon-root {
    fill:#3f3b3b;;
  }
  .wizard {
    border: 1px solid #dfdfdf;
    padding: 23px 10px 36px 53px !important;
    .label {
      font-family: Roboto;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }
    .content {
      margin-top: 22px;
      display: flex;
      justify-content: space-between;
      .slick-slider {
        width: 97%;
        .slick-prev {
          display: none !important;
        }
        .slick-next {
          &:before {
            content: url("../../assets/img/right-arrow.png");
          }
        }
      }
    }
  }
  .list-view {
    .search-header {
      .first-col {
        display: flex;
      }
      .search-input {
        margin-top: 0;
        margin-left: 15px;
        .MuiFilledInput-root {
          height: 48px;
          background: #ffffff;
        }
      }
      .trash,
      .copy {
        width: 48px;
        height: 48px;
        margin: 0;
        img {
          width: 12px;
          height: 12px;
        }
      }
      .copy {
        margin: 0 15px;
      }
      .main-btns {
        display: flex;
        .templates-btn,
        .povs-btn {
          margin: 0 10px;
        }
      }
    }
    .more-icon {
      cursor: pointer;
    }
    .text-gray {
      color: #929292;
    }
    .custom-table {
      thead {
        tr {
          th {
            &:nth-child(1) {
              width: 74px;
            }
            &:nth-child(2) {
              width: 250px;
            }
            &:nth-child(3) {
              width: 440px;
            }
          }
        }
      }
      .name-col {
        font-family: Roboto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 250px;
        font-size: 12.5px;
      }
    }
  }
  .mr-45 {
    margin-right: 45px;
  }
  @media only screen and (max-width: 992px) {
    margin: 18px 0;
    .new-template {
      width: 100%;
    }
    .wizard {
      width: 100%;
      margin-top: 15px;
      .content {
        .slick-slider {
          max-width: 600px;
        }
      }
    }
    .list-view {
      width: 100%;
      .search-header {
        .main-btns {
          .templates-btn,
          .povs-btn {
            margin: 10px 0;
          }
        }
      }
    }
  }
}

.new-template-modal {
  .main-container {
    padding: 72px 34px 111px 46px;
  }
  .content {
    margin-top: 8px;
    .islive-checkbox {
      margin-top: 15px;
      .MuiCheckbox-root {
        padding: 0 15px 0 0;
      }
      .MuiFormControlLabel-label {
        font-weight: 300;
        font-size: 14px;
        color: #515050;
      }
      .MuiIconButton-label {
        background-color: white;
        border: 1px solid #d1d1d1;
        width: 20px;
        height: 20px;
      }
      .Mui-checked {
        .MuiIconButton-label {
          border: none;
        }
      }
    }
    .create-btn {
      font-family: Roboto;
      font-weight: normal;
      font-size: 14px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 0;
      margin-top: 25px;
    }
  }
}

.confirmation-modal {
  .main-container {
    padding: 36px 51px 41px 47px;
    .title {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      color: #0f1a2b;
    }
    .closebtn {
      margin-top: -85px;
      margin-left: 66px;
    }
    .confirmation-text {
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      color: #7d7d7d;
    }
    .warning-content {
      margin-top: 15px;
      background: rgba(223, 0, 0, 0.2);
      border: 1px solid #df0000;
      box-sizing: border-box;
      padding: 12px !important;
      font-style: normal;
      color: #df0000;
    }
    .btns {
      margin-top: 8px;
      display: flex;
      .delete {
        margin-right: 12px;
      }
    }
  }
}

.gray-btn { 
 
  box-sizing: border-box;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #0f1a2b;
  width: 48px;
  height: 48px;
  background: #FFFFFF  !important;
  border: 1px solid #E2E2E2 !important ;
  box-sizing: border-box;
}
n-left { margin-left: 15px;}

.mt-1{
  margin-top: 1em;
}

.pl-3{
  padding-left: 3em !important;
  }
  
  .ml-3{
    margin-left: 3em !important;
  }
  .ml-2{
    margin-left: 2em !important;
  }
  
  .create-card-title{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    color: #000000;
    margin-top: 2px;
    margin-bottom: 22px;
  }
  
  .design-create-card {
    padding: 30px 20px 30px 20px;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
    min-height: 193px;
    position: relative;
    text-align: center;
  }
  .d-card{
    padding: 0px 15px;
}
  .pl-0{
    padding-left:0px !important;
  }
  
  .new-design-button {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 26px;
    align-items: center;
    color: #FFFFFF;
    background: #F96B13;
    width: 111px;
    border: none;
    padding-top: 10px;
    height: 86px;
  }
  
  .download-design-button {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 26px;
    align-items: center;
    color: #3D3D3D;  
    width: 111px;
    border: none;
    padding-top: 10px;
    height: 86px;
    background: #F4F5F7;
  border: 1px solid #E2E2E2;
  opacity: 0.5;
  }
  
  .wizard-design-button {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    color: #3D3D3D;  
    width: 111px;
    border: none;
    padding-top: 10px;
    height: 86px;
    background: #F4F5F7;
  border: 1px solid #E2E2E2;
  margin: 0px 20px;
  }
  .mydesign-title{
    font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: left;
  color: #000000;
  text-align:left;
  margin-bottom: 0px;
  padding-left: 16px;
  }
  .delete-button{
    background: #FFFFFF;
    border: 1px solid #E2E2E2;
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    width: 5%;
    float: left;
    margin-right: 1%;
  }
  .search2-container {
    border: solid 1px #E2E2E2;
    width:93%;
    height: 48px;
    background: #FFFFFF;
    float: left;
    
  }
  .inputsearch {
    padding: 1px 0px 3px 0px;
    border: none;
    border-bottom: 1px solid #fff;
    margin-bottom: 3px;
    margin-top: 2px;
    box-sizing: border-box;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    width: 80%;
    color: #5A5A5A;
    line-height: 38px;
    float: left;
    padding-left:20px;
  }
  .searchicon{
    float: left;
    padding-left: 16px;
    padding-top: 14px;
    
  }
  .cisco-title{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    display: flex;
    align-items: left;  
    color: #0F1A2B;
    margin-bottom: 10px;
  }
  .timeleft {
    line-height: 19px;
    display: flex;  
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    margin-top: 0px;
  }
  .timeleft span {
    font-weight: 500;
    margin-top: 0px;
  }
  
  .MuiAppBar-colorPrimary{
    background:#fff !important;
  }

  header{
    background:#fff;
  }
  
.wizard-design-button img{
    padding-bottom: 6px;
}
.pt-3{
  padding-top:2em;
}
.makeStyles-search-section {
  margin: 23px 0;
}

.trash{

}
.trash span img{
  width: 24px !important;
  height: 24px !important;
 }


.design-card2 {
  background-color: transparent !important;
  width: 100% !important;
  margin: 0 auto;
  padding: 0px !important;
}

.notavailale{
  position: relative;
}

.design-card2 .design-card{
  background-color: transparent;
  width: 100%;
  margin: 0 auto;
  padding: 30px;
}

.design-card2 img.align-center{
  margin-top: 0px;
}
.design-card2 .upgrade-button {
  height: 31px;
}

@media screen and (max-width:767px) {

  .design-card2 .upgrade-button {
    height: 31px;
    position: relative;
    float: left;
}
.pl-8 {
  padding-left: 0em !important;
}

}