.loginContainer{
  padding: 15px;
  height: auto;
  .loginTitle{
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    color: #0F1A2B;
    align-self: center;
    margin-bottom: 30px;
  }
  .alertboxcontainer{
  box-sizing: border-box;
  font-weight: 500;
  margin-bottom: 25px;
  padding: 20px 25px;
  border-radius: 10px;
  font-size: 16px;
    color: black;
    border: 1px solid #4FAD2B;
    background: #E0F0DA;
  }
  .errorAlertBackground{
    box-sizing: border-box;
    font-weight: 500;
    margin-bottom: 25px;
    border-radius: 10px;
    padding: 20px 25px;
    font-size: 16px;
    color: black;
    border: 1px solid #DF0000;
    background: rgba(223, 0, 0, 0.2);
  }
  .customInputStyle {
    padding-bottom: 15px;
  }
  .claloginTitle{
    font-size: 36px;
    color: #0F1A2B;
  }
  .submit-button{
    margin-top: 35px
  }
}
.advertisingGridItemContainer{
  background: url("../../../assets/criterion_networks/img/background-image.png");
  height: 100% !important;
  z-index: 4;
}
.reset-password{
  color: #6f6f6f;
  width: 100%;
  height: 100%;
  margin: 0px !important;
  .loginGridItemContainer{
    background-color: #FFFFFF;
    height:100vh;
    z-index: 4;
  }
  .loginContainer {
    padding: 15px;
    height: auto;
  }
  .paragraph{
    margin-top: 15px;
    text-align: center;
    cursor: pointer;
  }
  .MuiSelect-icon{
    background: url("../../../assets/img/login/down_arrow.svg");
    background-repeat: no-repeat;
    width: 17px;
    margin-top: 7px;
    path{
      display: none;
    }
  }
  .MuiSelect-select:focus{
    background-color: unset !important;
  }
  .label-title{
    font-size: 13px;
    color: #888888;
  }
}

.question-options {
  .MuiListItem-root {
    &.Mui-selected {
      text-decoration: none;
      background-color: #F96B13;
      &:hover {
        background-color: #F96B13;
      }
    }
  }
}

#menu-questionSelect{
  z-index:9999 !important;
}