.label-tabs {
  display: flex;
  .tab {
    margin-right: 50px;
    cursor: pointer;
    font-family: Roboto;
    font-weight: normal;
    font-size: 16px;
    color: rgba(15, 26, 43, 0.5);
    padding-bottom: 8px;
    &.active {
      color: #0F1A2B;
      border-bottom: 2px solid #F96B13;
      border-radius: 1px;
    }
  }
}