.chat-toggle-icon {
  font-size: 30px;
  color: #F96B13;
  position: fixed;
  bottom: 50px;
  right: 50px;
  cursor: pointer;
  z-index: 1000;
  transition: transform 0.3s ease;
}
.chat-icon-toogle {
  font-size: 30px;
  color: #F96B13;
  position: fixed;
  bottom: 50px;
  right: 50px;
  cursor: pointer;
  z-index: 1000;
}
.chat-widget.minimized{
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 80px;
  right: 20px;
  background-color: #FFFFFF;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  z-index: 1000;
}
.chat-widget.maximized {
  width: 500px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 80px;
  right: 20px;
  background-color: #FFFFFF;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  z-index: 1000;
}
.chat-widget.minimized .chat-messages {
  height: 445px;
}
.chat-widget.maximized .chat-messages {
  height: 645px;
} 

.chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px 5px 10px;
  background-color: #0F1A2B;
  border-bottom: 1px solid #ccc;
  color:#FFFFFF;
}
.logo-image {
  width: 30px; 
  height: 30px;
  margin-right: 5px;
}

.chat-title {
  font-weight: 700;
  font-size: 18px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;;
}

.chat-container {
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url('./backgroundlogo.svg'); 
  background-size: 150px 150px; 
  background-repeat: no-repeat; 
  background-position: center; 
  z-index: 0; 
}

.chat-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9); 
  z-index: 1; 
}

.chat-messages {
  padding:10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative; 
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2; 
  height: 100%;
}



.message {
  margin-bottom: 10px;
  padding: 10px;
  color: #3C4858;
  word-wrap: break-word;
  word-break: break-word;
  display: inline-block;
  max-width: 80%;
  line-height: 1.5;
  position: relative;
}

.message.user {
  background-color: #D8D8D8;
  font-size: 16px;
  font-weight: normal;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;;
  text-align: right;
   align-self: flex-end;
   border-radius: 8px 0px 8px 8px;
   margin-right: 10px;
}

.message.bot {
  background-color: #fee9da;
  font-size: 16px;
  font-weight: normal;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;;
  text-align: left;
  align-self: flex-start;
  border-radius: 0px 8px 8px 8px;
  margin-left: 10px;
}
.message.user::after {
  content: "";
  position: absolute;
  right: -10px;
  top: 0;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #D8D8D8;
  border-bottom: 10px solid transparent;
  /* border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  border-bottom: 10px solid #5bb90b; */
}

.message.bot::before {
  content: "";
  position: absolute;
  left: -10px;
  /* top:0; */
  bottom: 0;
  width: 0;
  height: 0;
  /* border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fee9da;
  border-bottom: 10px solid transparent; */
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  border-bottom: 10px solid #fee9da;
}


.chat-input {
  padding: 10px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;;
  font-size: 14px;
  border: 1px solid #ccc;
  /* box-sizing: border-box; */
}
.chat-input .MuiFilledInput-root {
  border-radius: 5px !important;
  border: 1px solid #6F6F6F;
}
.chat-input .MuiFormControl-root {
  margin-top: 0px;
}
.chat-input .MuiFilledInput-root.Mui-focused {
  border: 1px solid #6F6F6F;
}
.clear-button {
  background-color: #F96B13;
  position:relative;
  border-radius: 10px;
}
.custom-dialog.minimized .MuiDialog-paper {
  position: absolute;
  margin-right: 45px;
  right: 0;
  width: 250px; 
  border-radius: 15px;
  bottom: 210px;
}

.custom-dialog.maximized .MuiDialog-paper {
  position: absolute;
  margin-right: 70px;
  right: 0;
  width: 400px; 
  border-radius: 15px;
  bottom: 320px;
}

.dialog-actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px 24px;
}

.cancel-button {
  align-self: flex-start;
  background: none;
}

.clear-button {
  align-self: flex-end;
  border-radius: 15px;
  background-color: #F96B13;
  position:relative;
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
  }
  40% {
      transform: translateY(-15px);
  }
  60% {
      transform: translateY(-10px);
  }
}

.animate-icon {
  animation: bounce 2s ease-in-out infinite;
}

.copy-button {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: #3C4858;
}

.copy-feedback {
  position: absolute;
  bottom: 30px;
  right: 5px;
  background-color: #0F1A2B;
  color: #FFFFFF;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
}

.loader-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start; 
  padding: 10px;
}
