.actionsRight {
    text-align: right
  }

  .pl0 {
    padding-left: 0!important;
  }

  .marAuto{
    margin: auto !important;
  }

  .p0{
    padding: 0 !important;
  }

  .btnWhite {
    background-color: unset !important;
    color: #F96B13 !important;
    border: 0 !important;
    font-size: 18px !important;
  }
  
.btnWhite:hover {
    background-color: unset;
    color: #F96B13;
}
  
  .normalFont {
    font-size: 18px;
    color: #000
  }
  .marginTop {
    margin-top: 45px
  }
  .description {
    font-size: 18px;
    margin-top: 20px
  }