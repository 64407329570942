/* [data-filepond-item-state='processing-complete'] .filepond--item-panel {
    background-color: #166683;
} */
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-react-inputs/styles/material.css"; 


/* background-color: transparent;
    border-color: transparent; */
@import "src/assets/scss/material-dashboard-pro-react/variables";

.e-file-delete-btn {
    display: none !important;
}

.e-btn.e-flat.e-primary, .e-css.e-btn.e-flat.e-primary .e-btn, .e-css.e-btn {
    
    color: white !important;
    box-shadow: 0 2px 2px 0 rgba(22, 102, 131, 0.14), 0 3px 1px -2px rgba(22, 102, 131, 0.2), 0 1px 5px 0 rgba(22, 102, 131, 0.12) !important;
    background-color: $criterion-blue !important;
    /* from material theme */
    /* width: 100% !important;
    height: 100% !important; */
    border: 0 !important;
    border-radius: 3px !important;
    padding: 11px !important;
    font-size: 1em !important;
    transition: all 0.1s ease !important;
    cursor: pointer !important;
    outline: none !important;
    margin-right: 1em !important;
}

.e-btn.e-flat.e-primary:disabled, .e-css.e-btn.e-flat.e-primary:disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
    border-color: transparent !important;
    box-shadow: none !important;
    color: rgba(0, 0, 0, 0.26) !important;
    /* from material theme */
    /* width: 100% !important;
    height: 100% !important; */
    border: 0 !important;
    border-radius: 3px !important;
    padding: 11px !important;
    font-size: 1em !important;
    transition: all 0.1s ease !important;
    cursor: pointer !important;
    outline: none !important;
    margin-right: 1em !important;
    font: 400 11px !important;
}

.e-file-clear-btn {
    color: white !important;
    box-shadow: 0 2px 2px 0 rgba(22, 102, 131, 0.14), 0 3px 1px -2px rgba(22, 102, 131, 0.2), 0 1px 5px 0 rgba(22, 102, 131, 0.12);
    background-color: #f44336 !important;
}


.e-upload .e-upload-actions .e-file-clear-btn {
    color: white !important;
    box-shadow: 0 2px 2px 0 rgba(22, 102, 131, 0.14), 0 3px 1px -2px rgba(22, 102, 131, 0.2), 0 1px 5px 0 rgba(22, 102, 131, 0.12);
    background-color: #f44336 !important;

    border: 0 !important;
    border-radius: 3px !important;
    padding: 11px !important;
    font-size: 1em !important;
    transition: all 0.1s ease !important;
    cursor: pointer !important;
    outline: none !important;
    margin-right: 0em !important;
    font: 400 11px !important;
}

.e-upload .e-upload-files .e-upload-file-list .e-file-container .e-progress-inner-wrap .e-upload-progress-bar.e-upload-progress {
    background: #f44336 !important;
}

.e-upload .e-upload-files .e-file-abort-btn.e-icons::before {
    content: '\e974';
    display: none !important;
}
.e-upload .e-upload-files .e-icons.e-file-pause-btn::before {
    content: '\e326';
    display: none !important;
}