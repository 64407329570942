.account-card{
    background: #FFFFFF;
    border: 5px solid #F0F0F0;
    box-sizing: border-box;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
    min-height:280px;
    position: relative;
    }
.account-title{
    font-family: Roboto;
    font-weight: bold;
    font-size: 20px;
    display: flex;
    align-items: center;
    color: #000000;
    padding-left: 35px;
    }

    .account-card-top {
        padding:10px 20px 20px 30px;
    }

    .account-wid-img1 {
        float: left;
        top: 32px;
        position: absolute;
    }

    .account-wid-img2 {
        float: right;
        top: 38px;
        position: absolute;
        right: 25px;
    }

    .account-sub-title {
        font-family: Roboto;
        font-style: normal;
        font-size: 14px;
        display: flex;
        align-items: center;
        color: gray;
        margin-bottom: 0px;
        z-index: 0;
        position: relative;
    }

    .account-inner-height {
        min-height: 50px;
        border: 1px solid #F0F0F0;
        padding: 10px 0px 10px 0px;
        background: #faf8f8;
        box-shadow: inherit;
    }

    .account-right{
        border-right: 2px solid #F0F0F0;
    }

    .createaccount-card{
        padding: 20px 0px 50px 50px;
        background: #FFFFFF;
        border: 1px solid #F0F0F0;
        box-sizing: border-box;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
        min-height: 334px;
        position: relative;
    }

    .createaccount-widget-title{
            font-family: Roboto;
            font-weight: bold;
            font-size: 24px;
            display: flex;
            align-items: center;
            color: #000000;
        }

        .inventory-card{
                padding: 30px 30px 10px 28px;
                background: #FFFFFF;
                box-sizing: border-box;
                position: relative;
                color: #000000;
                min-height: 80px;
            }

        .audit-log {
            border-top: 1px solid lightgray;
            border-bottom: 1px solid lightgray;
            padding: 20px 0px 20px 0px;
        }    

        .inventoryedit-card{
            padding: 20px 30px 30px 50px;
            background: #FFFFFF;
            box-sizing: border-box;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
            position: relative;
        }

        .audit-log-data {
            font-weight: 400;
            padding: 20px 5px 0px 0px;
        }

        .account-settings{
            padding: 0px 20px 0px 0px;
        }
.delete-icon svg {
    height: 30px;
    width: 15px;
    margin-top: -4px;
}
.MuiSnackbarContent-root {
      min-width: 0px;
}