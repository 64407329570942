.new-lock {
  width: 16px !important;
  height: 16px !important;
  position: inherit !important;
  background: white !important;
  margin-left: 9px;
}
.full-search{
 border:solid 1px #E2E2E2;
 width: 100%;
 height: 48px;
 }
.sortable-moves {
   /* cursor: move; */
   margin-bottom: 0;
   -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.14);
   box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.14);
   margin-bottom: 20px;
   padding: 15px 0 15px 60px
}

.sortable-moves {
 font-size: 14px;
   line-height: 1.55556em;
   list-style-type: none;
   margin-bottom: 0px;
   min-height: 12.55556em;
   padding-left: 14.11111em;
   position: relative;
   /* cursor: move; */
   padding-top: 25px;
   background-color: #fff;
   /* padding-bottom: 75px; */
   padding-right: 50px;
}

.sortable-moves img {
 position: absolute;
   height: 114px;
   width: 114px;
   left: 56px;
   border-radius:0px;
   top: 28px;
   background: black;
}

.sortable-moves .imgdefault span {
position: absolute;
}

.card-title{
font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 19px;
display: flex;
align-items: center;
color: #000000;
}
.card-content{
font-family: Roboto;
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 30px;
color: #000000;
}
.card-content span{ 
 font-weight: 500;
}
.sepbar{
 color: #D6D6D6;
 padding: 0px 20px;
}
.card-content a{
 font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 19px;
align-items: center;
text-align: center;
text-decoration-line: underline;
color: rgb(249 107 19 / 85%);
cursor: pointer;
}

.lock{
   fill: #F96B13;
   width: 16px;
   height: 16px;
   margin-left: 13px;
}
.topnav {
 overflow: hidden;
   background-color: #fff;
   margin: 0px 3%;
   padding: 25px 4%;
   box-shadow: 0 1px 5px 0 rgb(0 0 0 / 14%);
   display: flex;
}




.topnav a {
 float: left;
 display: block;
 color: black;
 text-align: center;
 padding:13px 0px;
 text-decoration: none;
 font-size: 17px;
 background: #F4F5F7;
 border: 1px solid #DEDEDE;
 font-weight: 500;
 font-size: 14px;
 min-width: 124px;
}


.topnav a:hover {
 /* background-color: #FFF; */
 color: black;
}

.topnav a.active {
 background-color: #FFF;
 color: #000;
}


.search-container{
 border:solid 1px #E2E2E2;
 width: auto;
height: 48px;
float: left;

flex: 1 1 auto; 
}


.all-tab{
 border: green solid 1px;
 float: right !important;
}

.search-container-right{
 float: right;  
 margin-left: 20px;
}

.topnav input[type=text] {
 padding: 15px 6px 6px 7px;
 font-size: 17px;
 border: none;
 width: 76%;
 line-height: 20px;
 float: left;

}

.topnav .search-container button {
 float: left;
 padding: 6px 10px;
 margin-top: 8px;
 margin-right: 16px;
 background: #ddd;
 font-size: 17px;
 border: none;
 cursor: pointer;
}

.topnav .search-container button:hover {
 background: #ccc;
}
/* .Mui-selected .MuiTab-wrapper{
   color: #F96B13;
 } */
 .MuiTab-wrapper{
   font-family: Roboto;
 font-style: normal;
 font-weight: bold;
 font-size: 14px;
 line-height: 16px;
 display: flex;
 align-items: center;
 /* color: #0F1A2B; */
 text-transform: capitalize;
 }
 .MuiAppBar-colorPrimary {
     /* color: #fff !important; */
     background-color: #F4F5F7 !important;
     box-shadow: none !important;
     padding-left: 3%;
 }
 .makeStyles-root-1{
   background-color: #F4F5F7 !important;
 }
 /* #simple-tabpanel-0 .MuiBox-root{
   padding-top: 0px;
 } */
 .MuiTab-textColorInherit {
     color: inherit;
     opacity: 1 !important;
 }
 .makeStyles-root-8{
   background-color: #F4F5F7 !important;
 }
 .MuiBox-root-6 {
     padding:0% 3% 3% 3%!important;
 }
 .MuiBox-root{ padding:0% 3% 3% 3%!important;}

 .page-title{
   font-family: Roboto;
   font-style: normal;
   font-weight: bold;
   font-size: 16px;
   line-height: 19px;
   display: flex;
   align-items: center;
   color: #6F6F6F;
   }
   .header{
   height: 94px;
   line-height:94px;
   background:#fff;
   }
   .profilename{
   font-family: Roboto;
   font-style: normal;
   font-weight: normal;
   font-size: 12px;
   line-height: 16px;
   text-align: right;
   color: #1D2029;
   float:right;
     padding-right: 17px;
     margin-top: 20px;
   }
   .profilename span{
   font-family: Roboto;
   font-style: normal;
   font-weight: normal;
   font-size: 10px;
   line-height: 16px;
   text-align: right;
   color: #ABB4BD;
   }
   .profile-icon{
     font-family: Roboto;
     font-style: normal;
     font-weight: normal;
     font-size: 16px;
     line-height: 36px;
     align-items: flex-end;
     color: #797979;
     background: #CBCBCB;
     width: 36px;
     height: 36px;
     float: right;
     text-align: center;
     border-radius: 100px;
       margin-top: 18px;
     margin-right: 34px;
   }
   .alertbox{
       float: right;
     border-right: #ddd solid 1px;
     height: 50px;
     line-height: 50px;
         padding-right: 24px;
     margin-right: 27px;
   
     margin-top: 11px;
   }
   
   .badge-danger {
     color: #fff;
     background-color: #F96B13;
     border-radius: 50px;
     vertical-align: top;
     margin-top: 5px;
     margin-left: -11px;
     font-size: 10px;
     width: 18px;
     height: 18px;
     display: inline-block;
     line-height: 18px;
     text-decoration: none;
   }
   .white-logo{
       text-align: center;
     float: left;
     width: 100%;
     padding-top: 35px;
    }
    
    /*
   .side-menu{
   float:left;
   width:100%;
       margin-top: 50px;
         padding-left: 0px;
   }
   
   .side-menu li.active a{
   font-family: Roboto;
   font-style: normal;
   font-weight: normal;
   font-size: 16px;
   line-height: 60px;
   display: flex;
   align-items: center;
   text-transform: capitalize;
   color: rgba(255, 255, 255, 0.5);
   background: #15253D;
   border-left:#F96B13 solid 4px;
   text-decoration: none;
   }
   .side-menu li a img{
       padding-right: 17px;
     padding-left: 53px;
   }
   
   .side-menu-inner li a{
    padding-left: 53px;
    line-height: 51px !important;
   }
   
   .side-menu li a{
   font-family: Roboto;
   font-style: normal;
   font-weight: normal;
   text-decoration: none;
   font-size: 16px;
   line-height: 60px;
   display: flex;
   align-items: center;
   text-transform: capitalize;
   color: rgba(255, 255, 255, 0.5);
   }
   .side-menu li a:hover{
   text-decoration:none;
   }
    */

   .exp-card {
     padding: 30px 20px 30px 30px;
     background: #FFFFFF;
     border: 1px solid #F0F0F0;
     box-sizing: border-box;
     box-shadow: 0px 4px 12px rgb(0 0 0 / 4%);
     min-height: 292px;
     position: relative;
   }
   
   .exp-widget-title {
     font-family: Roboto;
     font-weight: normal;
     display: flex;
     align-items: center;	
     font-size: 20px;
     line-height: 40px;
     display: flex;
     align-items: center;
     color: #0F1A2B;     

   }
   
   .exp-sub-title {
     display: flex;
     align-items: center;
     color: #0F1A2B;
     margin-bottom: 0px;
     z-index: 9999;
     position: relative;
   font-family: Roboto;
   font-style: normal;
   font-weight: bold;
   font-size: 45px;
   line-height: 53px;
   display: flex;
   align-items: center;
   color: #0F1A2B;
   margin-top: 12px;
   }
   
   
   .exp-wid-img {
     float: right;
     top: 16px;
     position: absolute;
     right: 0px;
   }
   .arrow-link{
     margin-top: 42px;
     float: left;
     cursor: pointer;
   }
   /* .pl-5{padding-left: 4em !important;}
   .pt-5{padding-top: 4em !important;}
   .pt-4{padding-top: 3em !important;} */
   .bg-gray{
      background: #F4F5F7;
   }
   .ml-5{padding-left: 4em !important;}
   .mr-5{padding-right: 4em !important;}
   

   .design-card {
     padding: 0px 20px 30px 0px; 
     box-sizing: border-box;
     position: relative;
   }
   
   
   .design-title{
     font-family: Roboto;
     font-style: normal;
     font-weight: normal;
     font-size: 36px;
     line-height: 36px;
     display: flex;
     align-items: center;
     color: #000000;
     margin-top: 0px;
     margin-bottom: 0px;
   }
   .pt-0{padding-top: 0px !important;}
   .pt-2 {padding-top: 2em !important;}
   .mr-3 {
     padding-right: 3em !important;
   }
   .upgrade-button{
     font-family: Roboto;
     font-style: normal;
     font-weight: normal;
     font-size: 14px;
     line-height: 16px;
     align-items: center;
     color: #FFFFFF;
     background: #F96B13;
     width: 218px;
     border: none;
     height: 48px;
     float: right;
   }
   
   .design-list{
     padding-bottom: 0px;
     margin-bottom: 0px;
     padding-left: 17px;
   }

   .notavailale{
     font-family: Roboto;
     font-weight: normal;
     font-size: 16px;
     line-height: 19px;
     display: flex;
     align-items: center;  
     color: rgba(15, 26, 43, 0.5);
     padding-top: 10px;
   }


   
   .design-card {
     padding: 0px 20px 30px 0px; 
     box-sizing: border-box;
     position: relative;
   }
   
   
   .design-title{
     font-family: Roboto;
     font-style: normal;
     font-weight: normal;
     font-size: 36px;
     line-height: 36px;
     display: flex;
     align-items: center;
     color: #000000;
     margin-top: 0px;
     margin-bottom: 0px;
   }
   
   .design-list li{
     font-family: Roboto;
     font-weight: normal;
     font-size: 14px;
     line-height: 16px;
     align-items: flex-start;
     color: #454545;
     text-align: left;
     padding-bottom: 16px;
   }
   
   .pl-8{
     padding-left: 8em !important;
   }
   .sales-title{
   font-family: Roboto;
   font-style: normal;
   font-weight: bold;
   font-size: 24px;
   line-height: 28px;
   display: flex;
   align-items: center;
   color: #0F1A2B;
   text-align: left;
   }
   
   .panel {
     padding-right: 15px;
     width: 100% !important;
     /* height: 44px; */
     overflow: hidden;
     margin-bottom: 25px;
     position: relative;
     float: left;
 }
 .panel p {
   margin: 0px 0px 0px;
}









@media screen and (max-width:1400px) {

 .topnav input[type=text] {
   width: 72%;  
 }

 }

 @media screen and (max-width:1250px) {

  .topnav input[type=text] {
    width: 58%;  
  }
 
  }

  

   @media screen and (max-width:768px) {
   .home-widget-title {
     line-height: 35px;
       line-height: 22px;
     padding-top: 10px;
   }
   
   }
   @media screen and (max-width:767px) {
    .sortable-moves .imgdefault span{
      position: unset;
    }

   .home-widget-title {
     line-height: 35px;
       line-height: 22px;
     padding-top: 10px;
   }
   .goto-button {
     font-size: 13px;
     }
   .login-left { margin-left: 15px;}
   .ml-5 {  padding-left:1em !important; }

   .mr-5 {  padding-right: 1em !important;  }
   .exp-sub-title {    
     font-size: 24px;
   }
   .exp-widget-title {
     font-size: 16px;
   }
   .sortable-moves {     
     padding-left: 22px;
     padding-right: 22px;
   }
   .sortable-moves img {
     position: relative;
     height: 114px;
     width: 114px;
     left: 45px;
     border-radius: 0px;
     top: 0px;
     background: black;
  }
  .card-content {
    font-size: 15px;
   line-height: 26px;
  }
  .launch-demo .MuiButton-root {
   margin: 0 0 0 2px;
   margin-top: 10px;
   }
 .card-title { margin-top: 15px;}
 .launch-demo {
   padding-right: 0px;
 }

 .right-button button {
   float: left; 
   padding-top: 12px;
 }

 .sortable-moves img {
   position: relative;
   height: auto;
   width: 100%;
   left: 0px; 
   top: 0px;
}

.topnav {
 display: inline-block;
}
.search-container {
 border: solid 1px #E2E2E2;
 width: 100%;
 height: 48px;
 float: left;
 flex: none;
}
.search-container-right {
 float: left;
 margin-left: 0px;
 width: 100%;
 margin-top: 20px;
}
   }