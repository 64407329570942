.heading {
  font-size: 16px;
  font-weight: bold;
  font-family: Arial;
}
.roman {
  list-style: lower-roman;
}
.alpha {
  list-style: lower-alpha;
}
.decimal {
  list-style: decimal;
}
.noneBullet {
  list-style-type: none;
}
.body {
  font-size: 12px;
}
.tab {
  margin-left: 40px;
}
.template-list {
  margin-left: 65px;
  list-style: lower-roman;
}
.heading-24 {
  font-weight: bolder;
  font-size: 24px;
  font-family: Arial;
}
.heading-16 {
  font-weight: bolder;
  font-size: 16px;
  font-family: Arial;
}
.heading-18 {
  font-weight: bolder;
  font-size: 20px;
  font-family: Arial;
}
.heading-20 {
  font-weight: bolder;
  font-size: 18px;
  font-family: Arial;
}
.noneBullet {
  list-style-type: none;
}

.body {
  font-size: 12px;
  line-height: 20px;
}

.tab {
  tab-size: 8;
}

.ml-40 {
  margin-left: 50px;
}
.width-100 {
  width: 100%;
}
//.getMore2{
//  height: 100%;
//  width: 100%;
//  display: flex;
//}
