.dashboard {
  margin: 46px 74px 0 67px;
  @media only screen and (max-width: 768px) {
    margin: 25px 0 0 0;
  }
  .feedback {
    margin-top: 10px;
    background: #ffffff;
    border: none;
    box-shadow: none;
    border-radius: 0;
    // padding: 27px 27px 18px 27px;
    .fb-title {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      color: #000000;
    }
    .fb-image {
      margin: 25px 0;
      height: 140px;
      background: #f1f1f1;
    }
    .description {
      margin-top: 15px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 19px;
      color: #606060;
    }
  }
  .dashboard-img {
    max-width: 534.77px;
    max-height: 486.52px;
  }
  .dashboard-card {
    height: 600px;
  }
  .details-section {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border: none;
    height: 493px;
    padding: 0 63px 18px 27px;
    background: #ffffff;
    box-shadow: none;
    margin-top: 10px;
    border-radius: 0;
  }
  .learn-more-button {
    cursor: pointer;
    border: 1px solid #f96b13;
    width: 119px;
    height: 37px;
    color: #f96b13;
    background-color: #ffffff;
  }
  .align-center {
    justify-content: center;
    display: flex;
    margin-top: 70px;
  }
  .m {
    margin-top: 46px;
    margin-left: 10px;
    margin-bottom: 89px;
  }
  .content {
    display: contents;
    .feature-section {
      width: 254px;
      background: #ffffff;
      border: none;
      box-shadow: none;
      border-radius: 0;
      padding: 27px 27px 18px 27px;
      margin: 18px;
      &:first-child {
        margin-left: 15px;
      }
    }
  }
}

.helpsidebarlinks {
  .sidebarLinks {
    color: #8f8a8a;
    padding: 10px;
    &:hover {
      //text-decoration: underline;
    }
  }
}

.dashboard-modal .closebtn {
  margin-top: -223px !important;
  margin-left: 274px !important;
}

.upgradeModal {
  position: absolute;
  width: 850px !important;
  background-color: #eeeeee;
  padding: 10px;
}

.home-card{
  padding:30px 20px 30px 30px;
  background: #FFFFFF;
  border: 1px solid #F0F0F0;
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
  min-height:275px;
  position: relative;
  height: 100%;
  }
  
  .home-widget-title{
  font-family: Roboto;
  font-weight: bold;
  font-size: 20px;
  line-height:52px;
  display: flex;
  align-items: center;
  color: #000000;
  background:url(../../assets/img/title-top.png) no-repeat top left;
  }

  .exp-widget-title{
    background:url(../../assets/img/title-top.png) no-repeat top left;
    }

  .home-wid-img{
  float: right;
    top: 16px;
    position: absolute;
    right: 35px;
  }
  .home-wid-img2 {
    float: right;
    top: 16px;
    position: absolute;
    right: 18px;
    opacity: .5;
  }
  .home-sub-title{
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #0F1A2B;
      margin-bottom: 0px;
        // z-index: 9999;
        z-index: 0;
    position: relative;
    
  }
  
  .goto-button{
    height: 40px;
    background: #0F1A2B;
    min-width: 191px;
    color: #fff;
    margin: 0 auto;
    font-size: 16px;
    border:none
  }
  
  .sub-title-link{
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #F96B13;
    padding-top: 7px;
  }
  .arrow{margin-left:7.2px;margin-bottom: 5.5px;}
  
  
  .box-inner-height {
    min-height: 40px;
  }
  .mt-2 {
    margin-top: 2em !important;
}

.setting-input div{
  margin-top: 0px !important;
}
.information{
  border-radius: 5px;
  background-color: #606060;
  color: #fff;
  font-size: 12px;
  padding: 1px 10px;
  margin-top: 5px;
  display: inline-block;
}


@media screen and (max-width:768px) {
	.home-widget-title {
    line-height: 35px;
	    line-height: 22px;
    padding-top: 10px;
	}
	
}
@media screen and (max-width:767px) {
	.home-widget-title {
    line-height: 35px;
	    line-height: 22px;
    padding-top: 10px;
	}
	.goto-button {
    font-size: 13px;
    }
	.login-left { margin-left: 15px;}

}