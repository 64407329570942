.experience-page, .learning-lab {
  .ml-60 {
    margin-left: 60px;
    margin-top: 12px;
  }
  .learning-card {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
  }
  .first-section {
    display: flex;
    align-items: center;
  }
  .search-header {
    .first-col {
      display: flex;
    }
    .search-input {
      margin-top: 0;
      margin-right: 10px;
      width: 558px;
      .MuiFilledInput-root {
        height: 48px;
        background: #ffffff;
      }
    }
    .trash {
      width: 48px;
      height: 48px;
      margin-right: 15px;
      margin-left: 0;
      img {
        width: 12px;
        height: 12px;
      }
    }
    .main-btns {
      display: flex;
    }
    .launch-btn {
      text-align: right;
    }
  }
  .tableView {
    .white-section{
      padding: unset;
    }
    table {
      thead {
        tr {
          th {
            &:nth-child(1) {
              width: 74px;
            }
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:nth-child(7) {
            text-align: right;
            padding-right: 15px;
          }
        }
      }
    }
    .user-col {
      display: flex;
      align-items: center;
      .user-profile {
        img {
          width: 42px;
          height: 42px;
        }
      }
    }
    .action-btn {

    }
  }
  @media only screen and (max-width: 992px) {
    .first-section {
      display: block;
      .ml-60 {
        margin-left: 0;
      }
    }
    .search-header {
      .first-col {
        display: block;
        .search-input {
          width: 100%;
        }
        .main-btns {
          margin: 10px 0;
        }
      }
    }
  }
}

.MuiButtonBase-root {
  &.gray-btn {
    margin: 0 5px;
    opacity: 0.8;
    border: 1px solid #6B727D;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #6B727D;
    &.active {
      background: #CBCBCB;
      border: 1px solid #0F1A2B;
      color: #0F1A2B;
    }
    &:disabled {
      border: 1px solid #6B727D;
      color: #6B727D;
      opacity: 0.4;
    }
  }
}