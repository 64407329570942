.tableView {
  tr {
    th {
     p {
       display: inline-block;
     }
    }
  }

  .white-section {
    padding: 16px;
    .custom-table {
      .MuiTableCell-root {
        // border-bottom: 5px solid green
      }
      .MuiTableCell-body {
        color: black
      }
      .MuiFormControl-root {
        margin-top: 0
      }
      .MuiCheckbox-root {
        color: #DADADA;
        &.Mui-checked {
          color: #F96B13;
        }
      }
      .checkbox {
        &.MuiIconButton-root:hover {
          &:hover {
            background-color: #ffffff !important;
          }
        }

      }
    }
  }
  .asc {
    margin-left: 11px;
  }
  .desc {
    margin-left: 11px;
    transform: rotate(-180deg);
  }
  .footer-font {
    font-weight: 300;
    font-size: 14px;
    //line-height: 16px;
    //display: flex;
    //align-items: center;
    //text-transform: capitalize;
    color: #0F1A2B;
    opacity: 0.6;
  }
  .ml-13 {
    margin-left: 13px;
  }
  .MuiTableCell-head {
    font-size: 14px;
    font-weight: normal;
    color: #9A9A9A;
  }
  .MuiTableCell-body{
    font-size: 12px;
  }
  .display-page {
    border-color: #FFFFFF;
    width: 107px;
    height: 24px;
    background: #FFFFFF;
    color: #0F1A2B;
    font-weight: 300;
    font-size: 14px;
    opacity: 0.6;
    text-align: center;
  }
  .MuiButton-root {
    padding: 1px !important;
    .MuiButton-label {
      font-weight: 300;
      font-size: 14px;
      background-color: #fff !important;
      color: #0F1A2B !important;
      &:active {
        color: #fff !important;
        background-color: #3868C2 !important;
      }
    }
    &.active-page {
      .MuiButton-label {
        background-color: #3868C2 !important;
        font-weight: 300;
        font-size: 14px;
        color: #fff !important;
      }
    }
  }
  .table-pagination {
    .MuiPagination-ul {
      li {
        &:first-child {
          .MuiPaginationItem-page {
            background: unset;
            svg {
              display: none;
            }
            &:after {
              content: "Previous";
              font-family: Roboto;
              font-weight: 300;
              font-size: 14px;
              color: #0F1A2B;
              margin-right: 40px;
            }
          }
        }
        &:last-child {
          .MuiPaginationItem-page {
            background: unset;
            svg {
              display: none;
            }
            &:after {
              content: "Next";
              font-family: Roboto;
              font-weight: 300;
              font-size: 14px;
              color: #0F1A2B;
            }
          }
        }
      }
    }
    .MuiPaginationItem-page {
      background: #FFFFFF;
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      color: #0F1A2B;
      border: 0;
      border-radius: 0;
      margin: 0;
      width: 24px;
      height: 24px;

      &.Mui-selected {
        background-color: #3868C2 !important;
        font-weight: 300;
        font-size: 14px;
        color: #fff !important;
      }
    }
  }
}

