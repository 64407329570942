.launch-demo {
  padding-right: 20px;
  .go-back {
    cursor: pointer;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #818181;
    margin-top: 23px;
    display: block;
  }
  .Main-card {
    margin-bottom: 10px;
    background-color: white;
    line-height: 14px;
  }
  .ml-60 {
    margin-left: 60px;
    margin-top: 12px;
  }
  .first-section {
    display: flex;
    align-items: center;
    .page-title {
      font-size: 36px;
      font-weight: 400;
      color: black;
      margin-top: 0;
    }
  }
  .main-view {
    margin-top: 55px;
    padding-right: 35px;
  }
  // .bottom-button {
  //   // border-bottom: 1px solid;
  //   // font-size: 12px;
  //   // padding: unset;
  // }
  .Card-Image {
    max-width: 100%;
    max-height: 100%;
    border: 8px solid #ebebeb;
    padding: 0px !important;
    margin: 20px 0;
  }
  .labType {
    display: inline-block;
    cursor: pointer;
    padding: 0 40px 0 5px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin: 5px 0;
    color: #000000;
    &.active {
      background: #ebebeb;
    }
  }
  .MuiButton-root {
    margin: 0 0 0 22px;
  }
  .MuiGrid-align-items-xs-flex-end {
    align-items: unset;
  }
  .MuiGrid-root .makeStyles-grid-177 h4 {
    color: #0f1a2b !important;
  }
  .MuiGrid-root .makeStyles-grid-177 img {
    height: 160px;
    width: 160px;
    margin: 16px 0;
  }
  .Card-button {
    background: #ebebeb;
    font-size: 10px;
    color: #6a6a6a;
  }
  .ft-line {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    .label-text {
      font-weight: normal;
      font-size: 12px;
      color: rgb(136, 136, 136);
      margin-right: 20px;
    }
    .lock {
      width: 20px;
      height: 20px;
      margin-left: 10px;
    }
  }
  .panel {
    width: 80%;
  }
}


.card-left{
   float:left;
   margin-top: -8px;
}
.card-right{
  float: right;
  margin-top:25px;
}
.sandbox-first-tabs h6{
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #000000;
}

.sandbox-first-tabs div {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.sandbox-first-tabs button {
  height: 13px;
  margin-right: 40px;
}

.progressbar li{
  word-wrap: break-word;
  float: left;
  width: 20%;
  position: relative;
  text-align: center;
  list-style: none;
  font-size: 14px;
  color: #000;
}

.progressbar {
  counter-reset: step;
  z-index: 9;
  position: relative;
}
.progressbar li:before{
  content: counter(step);
  counter-increment: step;
  width: 14px;
  height: 14px;
  border: 2px solid #fff;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 14px;
  background: #C4C4C4;
  color: #C4C4C4;
  text-align: center;
  font-weight: bold;
}
.progressbar li:after{
  content: '';
  position: absolute;
  width:100%;
  height: 1px;
  background: #979797;
  top:9px;
  left: -50%;
  z-index: -1;
}
.selected{
  color:#F96B13 !important ;
}
.inactive{
  color: #C4C4C4 !important ;
}
.progressbar li:first-child:after{
  content: none;
  }

 .progressbar li.active + li:after{
  background: #F96B13;
 }

 .progressbar li.active + li:before{
 border: #FFF solid 2px;
 background: #F96B13;
 color:#F96B13
 }

 .progressbar li.active:before{
  border: #FFF solid 2px;
  background: #F96B13;
  color:#F96B13
  }