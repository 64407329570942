@import "src/assets/scss/material-dashboard-pro-react/variables";

.filterchip {
  display: inline-block;
  padding: 0 25px;
  line-height: 45px;
  border-radius: 25px;
  background-color: $gray-lighter;
  margin: 5px 5px 0 0;
}
.filterchip:hover{
  background-color: $gray-light;
}

.filter-closebtn {
  padding-left: 10px;
  color: $gray-dark;
  font-weight: bold;
  float: right;
  font-size: 20px;
  cursor: pointer;
}

.filter-closebtn:hover {
  color: $gray-base;
}

.row-expand {
  color: $table-row-expand-color;
  padding-top: 20px;
  font-size: 16px;
}

.row-expand-caret {
    width: 0;
    height: 0;
    display: inline-block;
    border-top: 4px solid;
    transition: all 150ms ease-in;
    border-left: 4px solid transparent;
    margin-left: 4px;
    border-right: 4px solid transparent;
    border-width: 10px;
    vertical-align: middle;
}

.card-space {
  background-color: rgba(183, 183, 183, 0.12);
  height: 12px;
}

.dropdown-btn {
  // width: 213px !important;
  // height: 30px !important;
  // border: 1px solid #C4C4C4;
  // color: #7B7B7B;
  // background-color: #FFFFFF;
  // box-shadow: none !important;
  // justify-content: flex-start;
  // padding: 10px 25px;
  // font-size: 14px;
  text-transform: none
}

.dropdown-btn:hover, .dropdown-btn:focus {
  // border-color: #C4C4C4;
  color: #7B7B7B !important;
  background-color: #FFFFFF !important;
  // box-shadow: none !important;
  // justify-content: flex-start;
  // padding: 10px 25px;
  // font-size: 14px;
  // text-transform: none
}

.dropdown-caret {
  // border-right: 0px;
  // border-bottom: 0px;
  // border-left: 0px;
  border-top: 0px solid transparent !important;
  width: 0px !important;
  height: 0px !important;
  margin-bottom: auto;
  margin-left: auto !important;
  // transform: rotate(45deg)
}

// .dropdown-caret-open {
//   display: block;
//   transform: rotate(90deg);
//   transition: all ease-in 0.2s;
// }

// .dropdown-caret-down {
//   display: block;
//   transform: rotate(270deg);
//   transition: all ease-in 0.2s;
// }

.Table__itemCount {
  /* margin-top: 10px; */
  font-size: 14px;
}

.Table__pagination {
  display: flex;
  justify-content: flex-end;
  padding: 20px 10px;
}

.Table__pageButton {
  font-size: 14px;
  outline: none;
  border: none;
  font-weight: 300;
  background-color: transparent;
  cursor: pointer;

}

.Table__nextPageWrapper .Table__pageButton {
  color: $criterion-orange;
}

.Table__prevPageWrapper .Table__pageButton {
  color: $criterion-orange;
}

.Table__pageButton:disabled {
  cursor: not-allowed;
  color: #686868;
}

.Table__pageButton--active {
  color: $criterion-orange;
}

.input-label-pseudo {
  color: #0F1A2B !important;
}

.input-pseudo::before {
  border-bottom: none !important;
}

.input-pseudo::after {
  border-bottom: 2px solid $criterion-orange !important;
}

.button-pseudo:hover {
  background-color: $criterion-orange !important;
}

.button-pseudo-hollow:hover {
  color: $criterion-orange;
  background: transparent !important;
}

.strong-password-label-pseudo {
  color: #0F9D58 !important;
}

.strong-password-pseudo::after {
  border-bottom: 2px solid #0F9D58 !important;
}

.medium-password-label-pseudo {
  color: #F4B400 !important;
}

.medium-password-pseudo::after {
  border-bottom: 2px solid #F4B400 !important;
}

.wrong-pass-lable-pseudo {
  color: red !important;
}

.wrong-pass-pseudo::after {
  border-bottom: 2px solid red !important;
}

.right-pass-lable-pseudo {
  color: #0F9D58 !important;
}

.right-pass-pseudo::after {
  border-bottom: 2px solid #0F9D58 !important;
}