
 .display-icon {
  font-size: 14px;
    line-height: 1.55556em;
    list-style-type: none;
    margin-bottom: 0px;
    height: 30px;
    /* padding-left: 14.11111em; */
    position: relative;
    /* cursor: move; */
    /* padding-top: 25px; */
    background-color: #fff;
    /* padding-bottom: 75px; */
    /* padding-right: 50px; */
 }
 
 .display-icon img {
  position: absolute;
    height: 40px;
    width: 75px;
    /* left: 56px; */
    border-radius:0px;
    /* top: 28px; */
    /* background: black; */
 }
 
 .display-icon .imgdefault span {
 position: absolute;
 }